import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-base text-right" }
const _hoisted_2 = { class: "text-base text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.invoices,
    paginator: true,
    filterDisplay: "menu",
    filters: _ctx.filters,
    "onUpdate:filters": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters) = $event)),
    rows: _ctx.rowsPerPage,
    rowsPerPageOptions: [5, 10, 25],
    selectionMode: "single",
    onRowSelect: _ctx.onRowSelect,
    paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
    currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} Items",
    class: "p-datatable-sm custom-date-range-table",
    breakpoint: "960px",
    responsiveLayout: "stack",
    loading: _ctx.isLoading
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-wrap align-items-center justify-content-between p-0 m-0", { 'p-1 m-0': !_ctx.isCustomTab }])
      }, [
        _createElementVNode("h3", {
          class: _normalizeClass(["text-center flex p-0 m-0 pl-3", {
            'text-start w-full p-0 m-0 pl-3': !_ctx.isCustomTab,
            'text-center flex p-0 m-0 pl-3': _ctx.isCustomTab,
          }])
        }, _toDisplayString(_ctx.tableName), 3),
        (_ctx.isCustomTab)
          ? (_openBlock(), _createBlock(_component_Calendar, {
              key: 0,
              modelValue: _ctx.dateRange,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event)),
              format: "MM-dd-yyyy",
              selectionMode: "range",
              manualInput: false,
              placeholder: "Select Date Range",
              showButtonBar: true,
              showIcon: true,
              class: "h-2rem"
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    empty: _withCtx(() => [
      _createTextVNode("No record found")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "invoice",
        header: "Invoice #",
        headerClass: "font-bold",
        class: "text-base",
        bodyClass: "font-normal text-center",
        showFilterMatchModes: false,
        showFilterOperator: false
      }, {
        filter: _withCtx(() => [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: _ctx.filters.invoice.constraints[0].value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.invoice.constraints[0].value) = $event)),
            class: "p-column-filter",
            placeholder: "Search by Id"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "due_date",
        header: "Due date",
        headerClass: "font-bold",
        class: "text-base",
        bodyClass: "font-normal text-center",
        sortable: true
      }),
      _createVNode(_component_Column, {
        field: "vendor_name",
        header: "Vendor",
        headerClass: "font-bold pl-5",
        class: "text-base",
        bodyClass: "font-semibold text-left",
        sortable: true,
        showFilterMatchModes: false,
        showFilterOperator: false
      }, {
        filter: _withCtx(() => [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: _ctx.filters.vendor_name.constraints[0].value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.vendor_name.constraints[0].value) = $event)),
            class: "p-column-filter",
            placeholder: "Search by Id"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "status",
        header: "Status",
        headerClass: "font-bold text-base",
        sortable: "",
        "body-class": "text-center",
        showFilterMatchModes: false,
        showFilterOperator: false
      }, {
        body: _withCtx(({ data }) => [
          _createTextVNode(_toDisplayString(_ctx.getStatusTitle(data.status)), 1)
        ]),
        filter: _withCtx(() => [
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.selectedStatus,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedStatus) = $event)),
            options: _ctx.stati,
            optionLabel: "name",
            optionValue: "code",
            placeholder: "Filter By Type",
            style: { width: '150px' }
          }, null, 8, ["modelValue", "options"])
        ]),
        filterclear: _withCtx(() => [
          _createVNode(_component_Button, {
            type: "button",
            label: "Clear",
            onClick: _ctx.handleClearFilterByStatus
          }, null, 8, ["onClick"])
        ]),
        filterapply: _withCtx(() => [
          _createVNode(_component_Button, {
            type: "button",
            label: "Search",
            onClick: _ctx.handleFilter
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "type",
        header: "Type",
        headerClass: "font-bold text-base",
        sortable: "",
        showFilterMatchModes: false,
        showFilterOperator: false,
        "body-class": "text-center"
      }, {
        body: _withCtx(({ data }) => [
          _createTextVNode(_toDisplayString(_ctx.getTypeTitle(data.type)), 1)
        ]),
        filter: _withCtx(() => [
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.selectedType,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedType) = $event)),
            options: _ctx.types,
            optionLabel: "name",
            optionValue: "code",
            placeholder: "Filter By Type",
            style: { width: '150px' }
          }, null, 8, ["modelValue", "options"])
        ]),
        filterclear: _withCtx(() => [
          _createVNode(_component_Button, {
            type: "button",
            label: "Clear",
            onClick: _ctx.handleClearFilterByType
          }, null, 8, ["onClick"])
        ]),
        filterapply: _withCtx(() => [
          _createVNode(_component_Button, {
            type: "button",
            label: "Search",
            onClick: _ctx.handleFilter
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "inv_amount",
        header: "Invoice Amount",
        headerClass: "font-bold currency-header",
        class: "text-base",
        sortable: true,
        bodyClass: "font-semibold text-right pr-2 lg:pr-3"
      }, {
        body: _withCtx(({ data }) => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.formatCurrency(data.inv_amount)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "balance",
        header: "Balance",
        headerClass: "font-bold currency-header",
        class: "text-base",
        sortable: true,
        bodyClass: "font-semibold text-right pr-2 lg:pr-3"
      }, {
        body: _withCtx(({ data }) => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.formatCurrency(data.balance)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ColumnGroup, { type: "footer" }, {
        default: _withCtx(() => [
          _createVNode(_component_Row, { class: "totals" }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                footer: 'Total Balance: ',
                colspan: 6,
                class: "text-right font-bold",
                footerStyle: "text-align:right"
              }),
              _createVNode(_component_Column, {
                footer: _ctx.getTotalBalance,
                class: "text-right font-bold",
                footerStyle: "text-align:right"
              }, null, 8, ["footer"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "filters", "rows", "onRowSelect", "loading"]))
}