
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Card from "primevue/card";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";

import AddDateRangeDialog from "../AddDateRangeDialog.vue";
import PayablesTable from "./PayablesTable.vue";

export default defineComponent({
  components: {
    Card,
    TabView,
    TabPanel,
    Button,
    AddDateRangeDialog,
    PayablesTable,
  },

  data() {
    return {
      activeTab: 0,
      dateRanges: [] as any[],
      extraDateRanges: [] as any[],
      showAddTabDialog: false,
      newTab: {
        label: "",
        range: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getActiveTab: "accountingPayables/getActiveTab",
      getDefaultDateRanges: "accountingPayables/getDefaultDateRanges",
      getCustomDateRanges: "accountingPayables/getCustomDateRanges",
      getNewPayableCount: "accountingInquiry/getNewPayableCount",
    }),
  },

  created() {
    this.activeTab = this.getActiveTab;
    this.dateRanges = this.getDefaultDateRanges;
    this.extraDateRanges = this.getCustomDateRanges;
  },

  methods: {
    ...mapActions({
      setActiveTab: "accountingPayables/setActiveTab",
      addCustomDateRange: "accountingPayables/addCustomDateRange",
      removeCustomDateRange: "accountingPayables/removeCustomDateRange",
      updateCustomDateRange: "accountingPayables/updateCustomDateRange",
      addOpenedAccountingTab: "accountingInquiry/addOpenedAccountingTab",
    }),
    handleTabChange(e: any) {
      this.activeTab = e.index;
      this.setActiveTab(e.index);
    },
    showAddTab() {
      this.showAddTabDialog = true;
    },
    closeAddTab() {
      this.showAddTabDialog = false;
      this.newTab = {
        label: "",
        range: "",
      };
    },
    addTab(data: any) {
      const index = this.getCustomDateRanges.findIndex(
        (range: any) => range.label.toLowerCase() === data.label.toLowerCase(),
      );
      const defaultLength = this.getDefaultDateRanges.length;
      if (index > -1) {
        this.updateCustomDateRange(data);

        this.activeTab = defaultLength + index;
        this.setActiveTab(defaultLength + index);
      } else {
        this.addCustomDateRange(data);
        this.activeTab = defaultLength + (this.getCustomDateRanges.length - 1);
        this.setActiveTab(
          defaultLength + (this.getCustomDateRanges.length - 1),
        );
      }
      this.showAddTabDialog = false;
    },
    handleCloseCustomTab(label: string, index: number, event: any) {
      event.stopPropagation();
      this.removeCustomDateRange(label);
      const defaultLength = this.getDefaultDateRanges.length;

      if (this.getActiveTab > 3 && this.getCustomDateRanges.length === 0) {
        this.activeTab = 3;
        this.setActiveTab(3);
      }

      if (this.getActiveTab > 3 && this.getCustomDateRanges.length > 0) {
        this.activeTab = defaultLength + (this.getCustomDateRanges.length - 1);
        this.setActiveTab(
          defaultLength + (this.getCustomDateRanges.length - 1),
        );
      }
    },
    handleAddPayable() {
      const count =
        this.getNewPayableCount === undefined ? 1 : this.getNewPayableCount + 1;
      const itemToEdit = {
        record: { ap_id: `New Payable-${count}` },
        accountType: "accounts-payable",
        old_record: {},
      };
      this.addOpenedAccountingTab(itemToEdit);
      this.$router.push(
        `/accounting/accounts-payable/${itemToEdit.record.ap_id}`,
      );
    },
  },

  watch: {
    getCustomDateRanges: {
      handler() {
        this.extraDateRanges = this.getCustomDateRanges;
      },
      deep: true,
    },
    getActiveTab(idx: number) {
      this.activeTab = idx;
    },
  },
});
